import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IngridientsContainer, NoData,/* CategoryTag,*/ CategoryContainer,/* CategoryHeader,*/ CategoryWrapper, RecipeTitle} from '../styles';
import { ImageUrlNew } from '../../../../utils/constants';

class RecipeIngredients extends Component{
  categoryList = (tags) => (
    tags.map((data, index) => (
      <CategoryWrapper key={index}>
        <div>
          <img src={`${ImageUrlNew}/${data['image']}`} />
        </div>
        <span>{data['recipe_tag']}</span>
      </CategoryWrapper>
    ))
  );
  render() {
    const {ingredients, categoryTagsList} = this.props;

    return (
      <div>
        <div>
          {ingredients && ingredients.length > 0 ?
            <IngridientsContainer>
              {
                ingredients.map((data, index) => (
                  <div key={index}>{data['ingredient_name']}</div>
                ))
              }
            </IngridientsContainer> : <NoData>No ingredients for this recipe</NoData>}
        </div>
        <RecipeTitle>Category</RecipeTitle>
        <CategoryContainer>
          {/* <CategoryHeader>Category</CategoryHeader> */}
          {categoryTagsList && categoryTagsList.length > 0 ? this.categoryList(categoryTagsList) : <NoData>No Category for this recipe</NoData>}
        </CategoryContainer>
      </div>
    );
  }
}
RecipeIngredients.propTypes = {
  ingredients: PropTypes.array,
  categoryTagsList: PropTypes.array
};
export default RecipeIngredients;