import React, { Component, Suspense } from 'react';
import { RecipeDirectionsContainer, RecipeSteps,/* RecipeImages,*/ Images, RecipeVideos, NoData,/* RecipeVideoContainer,MakeTheRecipe*/CategoryContainer, RecipeTitle, CategoryWrapper, End } from '../styles';
import PropTypes from "prop-types";
import Thumbnail from "../../../Thumbnail";
import { ImageUrl } from "../../../../utils/constants";
import { PlayIcon, VideoPlayer } from "../../../Content/DailyDashboard/styles";
import Loading from "../../../Loading";
import { Img } from 'react-image';
const DemoPopup = React.lazy(() => import("../../../WhyAmigoCommon/DemoPopup"));
import Waiting from '../../../Waiting/index'
import { ImageUrlNew } from "../../../../utils/constants";

class RecipeDirections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false
    }
  }
  recipeSteps = (directions) => (

    directions.map((data, index) => (
      <li key={index}>
        <div className="flex">
          <div className="vertical-line"  />
          <span>{data && data['description']}</span>
        </div>
        <End className="end" width={index===directions.length-1} />
      </li>
    )));

  categoryList = (tags) => (
    tags.map((data, index) => (
      <CategoryWrapper key={index}>
        <div>
          <img src={`${ImageUrlNew}/${data['image']}`} />
        </div>
        <span>{data['recipe_tag']}</span>
      </CategoryWrapper>
    ))
  );

  recipeImages = (imagesVideos) => (
    imagesVideos.map((data, index) => (
      <Images key={index}>
        <Img loader={<Loading />}
          src={`${ImageUrl}/${data['image']}`}
          alt={"recipe_image"}
          unloader={
            <Img
              src= {ImageUrl + '/images/placeholder/video-placeholder.jpg'}
              alt={"recipe_image"}
            />
          }
        />
      </Images>
    ))
  );
  updateUserPoints = () => {
    const { updateUserGlobalPoints, recipeDetails } = this.props;
    updateUserGlobalPoints(recipeDetails['id'], 23);
  };

  recipeVideos = (recipeDetails) => (
    <RecipeVideos>
      <VideoPlayer>
        <Thumbnail
          src={`${ImageUrl}/${recipeDetails['recipe_image']}`}
          alt={recipeDetails['title']}
          onClick={() => this.changeModal()}
          recipe={1}
        />
        <PlayIcon onClick={() => this.changeModal()}>
          <i className="fa fa-play" />
        </PlayIcon>
      </VideoPlayer>
    </RecipeVideos>
  );
  changeModal = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo
    }));
  }
  render() {
    const { directions, /*images,*/ recipeDetails, categoryTagsList } = this.props;
    const { showVideo } = this.state;
    const videoUrl = recipeDetails && recipeDetails['video_url'];
    const VideoTitle = recipeDetails && recipeDetails['name'];
    return (
      <React.Fragment>
        <RecipeDirectionsContainer>
          {/* <RecipeVideoContainer>
            {this.recipeVideos(recipeDetails)}
            <MakeTheRecipe>Watch How to Make This Recipe</MakeTheRecipe>
          </RecipeVideoContainer> */}
          <RecipeSteps>
            {directions.length > 0 ? <ol>{this.recipeSteps(directions)}</ol> : <NoData>No Directions for this recipe</NoData>}
          </RecipeSteps>
        </RecipeDirectionsContainer>
        <RecipeTitle>Category</RecipeTitle>
        <CategoryContainer>
          {/* <CategoryHeader>Category</CategoryHeader> */}
          {categoryTagsList && categoryTagsList.length > 0 ? this.categoryList(categoryTagsList) : <NoData>No Category for this recipe</NoData>}
        </CategoryContainer>
        {/* <RecipeImages>
          {images.length > 0 ? this.recipeImages(images) : null}
        </RecipeImages> */}
        <Suspense fallback={<Waiting/>}>
          {showVideo && <DemoPopup showModal={showVideo} updateUserPoints={this.updateUserPoints} onClose={this.changeModal} videoUrl={videoUrl} VideoTitle={VideoTitle} />}
        </Suspense>
      </React.Fragment>
    );
  }
}

RecipeDirections.propTypes = {
  directions: PropTypes.array,
  images: PropTypes.array,
  videos: PropTypes.object,
  recipeDetails: PropTypes.object,
  history: PropTypes.object,
  updateUserGlobalPoints: PropTypes.func,
  categoryTagsList: PropTypes.array
};
export default RecipeDirections;