import styled from 'styled-components';
import {ImageWrapperV2} from "../../ChallengeDetailsV2/styles";
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';
import {StyledTabContainer} from "../../ReportsV2/styles";
const BoldFont = 'Rubik-Medium';

const RecipeDetailsMainContainer = styled.div`
  width: 100%;
  padding-top: 25px;
  @media (max-width: 500px){
    padding: 20px;
  }
`;
const RecipeDetailsContainer = styled.div`
  width: 67.1%;
  float: left;
  @media(max-width: 1146px){
    width: 100%;
  }
`;
const FeaturedContainer = styled.div`
  width: ${({width}) => width ? '32.9%' : '35%'};
  float: left;
  padding: 0 5px 5px;
  padding: ${({padding}) => padding ? '0 0 0 25px' : '0 5px 5px'};
  @media(max-width: 1146px){
    width: 100%;
    padding: 0px;
    margin: 10px 0;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  position: relative;
  .bg-opacity {
    position: absolute;
    height: 230px;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  }
  .recipeName{
    position: absolute;
    font-family: ${MediumFont}
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
    top: 20px;
    left: 30px;
    max-width: 396px;
  }
`;
const AboutRecipe = styled.div`
  width: 100%;
  background-color: #fff;
  margin: 0 0 25px;
  border-radius: 6px;
  float: left;
  border-bottom:2px solid rgba(156,156,156,0.4);
  .title{
    font-family: rubik-medium;
    font-size: 16px;
    color: #9c9c9c;
  }
`;
const RecipeBody = styled.div`
  width: 100%;
  float: left;

  border-bottom: 2px solid rgba(156, 156, 156, 0.2);
  background: rgb(243,246,249);
  .recipeHeader{
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 15px;
    color: #0D4270;
    padding: 15px 0;
  }
  .recipeDescription{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #3A3A3A;
    padding-bottom: 25px;
  }
`;
const CookingDuration = styled.div`
  width: 100%;
  float: left;
  margin-top: 20px;
  padding: 25px;
  > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  @media(max-width: 600px){
    width: 100%;
  }
  @media(max-width: 400px){
    width: 100%;
    margin-top: 10px;
    > div{
      margin-top: 0px;
    }
  }
`;
const CookingDetails = styled.div`
  display: flex;
  width: calc(100% / 2);
  flex-wrap: wrap;
  @media (max-width: 400px){
    width: 100%;
    margin-top: 10px;
  }
  > span{
     color: #000;
     font-size: 13px;
     font-family: ${RegularFont}
  }
  > span:nth-child(2) {
    margin-left: 5px;
    font-family: ${MediumFont};
  }
`;
const RecipeInstructionSection = styled.div`
  width: 100%;
`;
const IngridientsContainer = styled.div`
  width: 100%;
  padding: 0px 10px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin: 20px 0;
  > div{
    font-size: 16px;
    color: #484856;
    font-family: ${RegularFont};
    border-bottom: 1px solid #E2E6EB;
    padding: 15px 15px;


    font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 18px;
color: #3A3A3A;
  }
  > div:last-child{
    border-bottom: none;
  }
`;
const FeaturedRecipesContainer = styled.div`
  width: 100%;
  margin-left: ${({marginLeft}) => marginLeft ? '0px' : '20px'};
  @media (max-width: 500px){
    margin-left: 0px;
  }
`;
const RecipeDirectionsContainer = styled.div`
  width: 100%;
  padding: 0px 25px;
  background: #FFFFFF;
  border-radius: 6px;
  margin: 20px 0;
  padding-top: 0px;
  padding-bottom: 15px;
`;
const RecipeSteps = styled.div`
   width: 100%;
   margin: 20px 0;
   & > ol {
    list-style: none;
    margin: 0;
    padding: 0;
    counter-reset: direction-counter;
    > li {
      position: relative;
      padding-bottom: 15px;
      width: calc(100% - 30px);
      font-size: 16px;
      counter-increment: direction-counter;
      margin: 0rem 0rem 0rem 3.35rem;
      nin-height: 100px;

      display: inline;
      top: 20px;

      .flex{
        display: flex;
        position: relative;

      
      & > span {
       display: flex;
       align-items:center;
       min-height:45px;
       line-height: 30px;
       margin-left: 25px;
       font-size: 16px;
       color: rgb(72, 72, 86);
       font-family:${RegularFont};

       font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #3A3A3A;

      position: relative;
      bottom: 20px;
      display: block;

      width: 99%;



      }
    }
    }
    .vertical-line{
      width: 2px;
      background-color:#EEEEEE;
      height: calc(100% - 50px);
      margin: 0 auto;

      width: 1px;
      background-color: #3A3A3A;
      min-height: 65px;
      height: auto;
      /* margin: 0 auto; */
      margin-left: 12px;
    }
    > li:before {
       content: counter(direction-counter);
       margin: 1rem 0rem 2.5rem -3.35rem;;
       font-size: 18px;
       line-height: 20px;
       font-family:${RegularFont};
       border-radius: 50%;
       border: 1px solid;
       padding: 2px 8px;

       font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 12px;
color: #3A3A3A;
    }
   }
`;
const StepsDetails = styled.div`
  width: 100%;
  display: block;
  >div{
    margin-bottom: 30px;
    width: 100%;
    display: block;
    vertical-align: top;
    > div: first-child{
      font-size: 40px;
      color: #000000;
      font-family:${RegularFont};
      width: 55px;
      display: inline-block;
      vertical-align: top;
    }
    > div: last-child{
      display: flex;
      align-items: center;
      width: calc(100% - 55px);
      font-size: 16px;
      color: #484856;
      font-family:${RegularFont};
      line-height: 28px;
      display: inline-block;
      vertical-align: top;
    }
  }
`;
const RecipeImages = styled.div`
  width: 100%;
  border-radius: 20px;
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Images = styled.div`
  display: flex;
  align-items: center;
  > img{
    border-radius: 20px;
    width: 250px;
    height: 200px
    margin-right: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
  }
`;
const RecipeVideoPlayerContainer = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  margin: 30px;
  @media (max-width: 760px) {
    display: block;
  }
`;
const RecipeVideos = styled.div`
  width: 235px;
  @media (max-width: 500px){
    width: 100%;
  }
`;
const NoData = styled.div`
  width: 100%;
  padding: 30px;
  font-size: 15px;
  font-family: ${MediumFont};
  background: #fff;
  margin: 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RecipeVideoContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 500px){
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
`;
const ImageWrapper = styled(ImageWrapperV2)`
  cursor: default
  height: 230px;
  border-radius: 6px;
  width: 100%;
  background-image: url(${({bgUrl}) => bgUrl});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  @media (max-width: 767px) and (min-width: 530px) {
    width: 100%;
    height: 230px;
  }
`;
const DetailedContainer = styled.div`
  width: 100%;
  height: 230px;
  position: absolute;
  top: 0;
  display: flex;
  > div: first-child{
    bottom: 20px;
    left: 30px
    position: absolute
  }
  > div: last-child{
    top: 15px;
    position: absolute;
    right: 15px;
  }
  .description{
    font-size: 18px;
    color: #FFFFFF;
    font-family: ${RegularFont}
    bottom: 30px;
    left: 45px;
    max-width: 395px;
  }
`;
const CategoryContainer = styled.div`
  width: 100%
  padding: 0px 0 25px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const CategoryHeader = styled.span`
  padding: 12px 15px 15px 0;
  color: #484856;
  font-size: 15px;
  font-family: ${MediumFont};
  border-radius: 25px;
  margin-bottom: 10px;
`;
const CategoryTag = styled.span`
  background-color: #fff;
  padding: 12px 15px;
  color: #484856;
  font-size: 13px;
  font-family: ${RegularFont};
  border-radius: 25px;
  margin: 0 10px 10px;
`;
const MakeTheRecipe = styled.div`
  font-family: ${MediumFont};
  font-size: 16px;
  color: #4798D9;
  width: calc(100% - 235px);
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 30px;
  @media (max-width: 500px){
    width: 100%;
    padding: 10px 0;
    justify-content: center;
  }
`;
const Button = styled.div`
width: 100px;
height: 35px;
margin: auto;
cursor: pointer;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #9C9C9C;
text-align: center;
padding: 10px 0;
>img{
    width: 9px;
    height: 5px;
}
`;

const VideoWrapper = styled.div`
width: 100%;
display: block;
background: white;
`;

const RecipeInfo = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
background: rgb(243,246,249);
padding-bottom: 15px;
`;

const RecipeInfoCards = styled.div`
width: calc((100% - 30px) / 3);
background: white;
margin-bottom: 15px;
border-radius: 6px;
> div: first-child{
  margin: 15px auto;
  display: flex;
  >img{
      margin: auto;
      height: 32px;
      width: auto;
  }
}
> div: last-child{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0px 35px;
  >span{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #3A3A3A;
  }
}
`;

const RecipeTitle = styled.div`
width: 100%;
font-family: Rubik-Medium;
font-weight: 500;
font-size: 18px;
line-height: 15px;
color: #0D4270;
margin: 25px 0px 15px;
`;

const CategoryWrapper = styled.div`
background-color: #fff;
border-radius: 25px;
margin: 0 10px 10px 0;
display: flex;
padding: 6px 10px;
>div{
  background:rgba(156,156,156,0.1)  ;
  border-radius: 50px;
  height: 32px;
  display: flex;
  justify-content: center;
  width: 32px;
  margin-right: 5px;
  >img{
    display: flex;
    height: 21px;
    width: auto;
    margin: auto;
  }
}
  >span{
    color: #484856;
    font-size: 13px;
    font-family: ${RegularFont};
    text-align: center;

    font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;
color: #3A3A3A;
display: flex;
margin: auto;
  }
`;

const StyledTabs = styled(StyledTabContainer)`
  width: 100%;
  .tab-content {
    position: relative;
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 0
    border-radius: 6px;
    padding:10px 20px 10px 20px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: ${BoldFont};
        margin-right: 2vw;
        padding:10px 15px;

        font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #9C9C9C;
        
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
          padding:10px 15px;
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      color: #0D4270;
      font-family: ${BoldFont};
      background: rgba(-23, 47, 71, 0.1);
      border: none;
      padding:10px 15px;
      border-radius: 3px;
    }
  }
  li.second-tab.active {
    > a {
      color:  #0D4270;
      font-family: ${BoldFont};
      background: rgba(-23, 47, 71, 0.1);
      border: none;
      padding:10px 15px;
      border-radius: 3px;
    }
  }
  li.third-tab.active {
    > a {
      color: #0169b2
      font-family: ${BoldFont};
      border: none;
      padding:10px 15px;
    }
  }
`;

const End = styled.div`
  width: 5px;
  width: ${({width}) => width ? '10px' : '0px'};
  height: 5px;
  height: ${({width}) => width ? '10px' : '0px'};
  background: #3a3a3a;
  border-radius: 50%;
  margin-left: 8px;
  `;

export {RecipeDetailsMainContainer, RecipeDetailsContainer, FeaturedContainer, ImageContainer, AboutRecipe, RecipeBody, CookingDuration, CookingDetails, RecipeInstructionSection, IngridientsContainer, FeaturedRecipesContainer, RecipeDirectionsContainer, RecipeSteps, StepsDetails, RecipeImages, Images, RecipeVideoPlayerContainer, RecipeVideos, NoData, RecipeVideoContainer, ImageWrapper, DetailedContainer, CategoryTag, CategoryContainer, CategoryHeader, MakeTheRecipe, Button, VideoWrapper, RecipeInfo, RecipeInfoCards, RecipeTitle, CategoryWrapper, StyledTabs, End};